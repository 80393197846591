import { useState, useCallback, type FormEvent, useEffect } from "react";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  query: string;
}

interface FormErrors {
  [key: string]: string;
}

interface Props {
  errors: { [key: string]: string };
  setErrors?: (
    value:
      | { [key: string]: string }
      | ((prev: { [key: string]: string }) => { [key: string]: string })
  ) => void;
}

const INITIAL_FORM_STATE: FormData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  query: "",
};

const FORM_VALIDATIONS = {
  firstName: (value: string) => (!value ? "First Name is required" : ""),
  lastName: (value: string) => (!value ? "Last Name is required" : ""),
  email: (value: string) => {
    if (!value) return "Email is required";
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      ? ""
      : "Email is not valid";
  },
  phoneNumber: (value: string) => {
    if (!value) return "Phone Number is required";
    return /^[6-9]\d{9}$/.test(value) ? "" : "Phone Number is not valid";
  },
  query: () => "",
} as const;

const SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycbwPm4dfwLcpukB8Uc4sZd0ZtEhC9x34DhHxA3u43e8eJt23gPw81lZ4-GI57GN7IgKJfQ/exec";

export default function ContactForm({ errors, setErrors = () => {} }: Props) {
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_STATE);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = useCallback(
    (field: keyof FormData, value: string) => {
      setFormData((prev) => ({ ...prev, [field]: value }));
      setErrors((prev) => ({ ...prev, [field]: "" }));
    },
    []
  );

  const validateForm = useCallback((): FormErrors => {
    const newErrors: FormErrors = {};

    (Object.keys(formData) as Array<keyof FormData>).forEach((field) => {
      const validationError = FORM_VALIDATIONS[field](formData[field]);
      if (validationError) {
        newErrors[field] = validationError;
      }
    });

    return newErrors;
  }, [formData]);

  useEffect(() => {}, []);

  const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);

      try {
        const formDataObj = new URLSearchParams();
        Object.entries(formData).forEach(([key, value]) => {
          formDataObj.append(key, value);
        });

        const response = await fetch(SCRIPT_URL, {
          method: "POST",
          body: formDataObj,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        await response.json();
        setFormData(INITIAL_FORM_STATE);
      } catch (error) {
        console.error("Submission error:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const getFieldLabel = (field: keyof FormData): string => {
    return field
      .replace(/([A-Z])/g, " $1")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="form-wrapper-style">
      <div className="form-header">GET IN TOUCH</div>
      <div className="form-style">
        {(Object.keys(formData) as Array<keyof FormData>).map((field) => (
          <div key={field} className="form-item">
            <input
              type={field === "email" ? "email" : "text"}
              value={formData[field]}
              onChange={(e) => handleInputChange(field, e.target.value)}
              className={errors[field] ? "error" : ""}
              required
            />
            {!formData[field] && <label>{getFieldLabel(field)}</label>}
            {errors[field] && (
              <span className="error-text">{errors[field]}</span>
            )}
          </div>
        ))}

        <button
          className="footer-botton-style"
          type="submit"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
}
