interface Props {
  src: string;
  title: string;
  description: string;
}

const ImageContainer = (props: Props) => {
  const { src, title, description } = props;

  return (
    <div className="image">
      <div className="card-image-wrapper">
        <img src={src} alt="title-image" className="card-style" />
      </div>
      <div className="text-wrapper">
        <div className="title">{title}</div>
        <div className="sub-title">{description}</div>
      </div>
    </div>
  );
};

export default ImageContainer;
