import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./FlipImage.scss";

const FlipCard = ({
  img1,
  img2,
  backgroundSize,
}: {
  img1: string;
  img2: string;
  backgroundSize: string;
}) => {
  const [flipped, setFlipped] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const flipVariants = {
    front: { rotateY: 0 },
    back: { rotateY: 180 },
  };

  useEffect(() => {
    if (parseInt(backgroundSize.split(" ")[0]) > 0) {
      setFlipped(true);
    } else if (parseInt(backgroundSize.split(" ")[0]) < 50) {
      setFlipped(false);
    }
  }, [backgroundSize]);

  return (
    <div
      className="flip-container"
      ref={containerRef}
      style={{ overflowY: "auto" }}
    >
      <motion.div
        className="flip-card"
        // animate={flipped ? "back" : "front"}
        variants={flipVariants}
        transition={{ duration: 0.2 }}
      >
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={img1} alt="Front" />
          </div>
          <div className="flip-card-back">
            <img src={img2} alt="Back" />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default FlipCard;
