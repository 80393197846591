import React, { useEffect, useRef, useState } from "react";
import FlipCard from "./FlipImage";
import { motion } from "framer-motion";

interface Props {
  content: string;
  heading: string;
  img1: string;
  img2: string;
  dark?: boolean;
}

const SvgScrollAnimation = ({
  content,
  heading,
  img1,
  img2,
  dark = false,
}: Props) => {
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const [backgroundSize, setBackgroundSize] = useState("0% 100%");

  useEffect(() => {
    const handleScroll = () => {
      if (!descriptionRef.current) return;

      const rect = descriptionRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const startReveal = windowHeight / 2;

      if (rect.top < startReveal && rect.bottom > 0) {
        const totalDistance = rect.height;
        const scrollDistance = Math.min(
          Math.max(startReveal - rect.top, 0),
          totalDistance
        );
        const percentage = (scrollDistance / totalDistance) * 100;

        setBackgroundSize(`${percentage}% 100%`);
      } else {
        setBackgroundSize("0% 100%");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`description-section ${dark ? "dark-section-style" : ""}`}>
      <div className="edges" />
      <div className="heading">
        <span>[ &emsp;</span> {heading}
      </div>
      <div className="text-wrapper-content-section">
        <FlipCard img1={img1} img2={img2} backgroundSize={backgroundSize} />
        <div className="description" ref={descriptionRef}>
          <motion.span animate={{ backgroundSize: backgroundSize }}>
            {content}
          </motion.span>
        </div>
      </div>
    </div>
  );
};

export default SvgScrollAnimation;
