import React, { RefObject } from "react";
import {
  Facebook,
  Twitter,
  Linkedin,
  Instagram,
  Mail,
  Phone,
  MapPin,
  LucideIcon,
} from "lucide-react";

type FooterProps = {
  footerRef: RefObject<HTMLElement>;
};

interface ContactInfo {
  icon: LucideIcon;
  text: string;
}

interface SocialIcon {
  icon: LucideIcon;
  href: string;
}

const Footer: React.FC<FooterProps> = ({ footerRef }) => {
  const quickLinks: string[] = ["Projects", "Contact"];

  const contactInfo: ContactInfo[] = [
    { icon: MapPin, text: "123 Construction Ave, Mumbai, India" },
    { icon: Phone, text: "+91 123 456 7890" },
    { icon: Mail, text: "info@buildcraftindia.com" },
  ];

  const socialIcons: SocialIcon[] = [
    { icon: Facebook, href: "#" },
    { icon: Twitter, href: "#" },
    { icon: Linkedin, href: "#" },
    { icon: Instagram, href: "#" },
  ];
  return (
    <footer className="footer" ref={footerRef}>
      <div className="footer-wrapper">
        <div className="footer-container">
          <div className="footer-grid">
            <div className="footer-brandSection">
              <a href="/" className="footer-brandLink">
                <div className="footer-logo">
                  <span className="footer-logoText">H</span>
                </div>
                <span className="footer-brandName">The Heavenly Group</span>
              </a>
              <p className="footer-description">
                Building the future of India with innovation, quality, and
                sustainability.
              </p>
            </div>

            <div>
              <h3 className="footer-sectionTitle">Quick Links</h3>
              <ul className="footer-linkList">
                {quickLinks.map((link) => (
                  <li key={link}>
                    <a
                      href={`/${link.toLowerCase().replace(" ", "-")}`}
                      className="footer-link"
                    >
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="footer-sectionTitle">Contact Us</h3>
              <ul className="footer-contactList">
                {contactInfo.map(({ icon: Icon, text }, index) => (
                  <li key={index} className="footer-contactItem">
                    <Icon size={18} />
                    <span>{text}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="footer-sectionTitle">Follow Us</h3>
              <div className="footer-socialIcons">
                {socialIcons.map(({ icon: Icon, href }, index) => (
                  <a key={index} href={href} className="footer-socialLink">
                    <Icon size={24} />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <p>
              &copy; {new Date().getFullYear()} The Heavenly Group India. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
