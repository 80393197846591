import React, { useEffect, useRef } from "react";
import FaqSection from "./FaqSection";

const SvgScrollAnimation: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (!section) return;

      const { top, height } = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Start the animation after scrolling down 100 pixels
      const scrollOffset = 200;
      const scrollProgress = Math.min(
        Math.max(
          (windowHeight - top - scrollOffset) /
            (height / 2 + windowHeight - scrollOffset),
          0
        ),
        1
      );

      const lines = document.querySelectorAll<SVGPathElement>(".line");

      lines.forEach((line) => {
        const totalLength = line.getTotalLength();
        // Set strokeDashoffset to decrease from total length to 0
        const offset = totalLength * (1 - scrollProgress);
        line.style.strokeDashoffset = offset.toString();
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div ref={sectionRef} className="section">
      <div className="svg-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 420 1324"
          className="mobile-screen-lines"
        >
          <g clipPath="url(#clip0_2360_450)">
            <path
              d="M215.65 1C197.495 314.56 212.748 1005.55 419 1261.05"
              className="line"
              style={{
                strokeDasharray: 1296.01,
                strokeDashoffset: 1296.01,
                stroke: "#FFFFFF",
              }}
            ></path>
            <path
              d="M204.35 1C222.505 314.56 207.252 1005.55 1 1261.05"
              className="line"
              style={{
                strokeDasharray: 1296.01,
                strokeDashoffset: 1296.01,
                stroke: "#FFFFFF",
              }}
            ></path>
            <path
              d="M249.475 146.997V1324"
              className="line"
              style={{
                strokeDasharray: 1177,
                strokeDashoffset: 1177,
                stroke: "#FFFFFF",
              }}
            ></path>
            <path
              d="M171.5 146.997V1324"
              className="line"
              style={{
                strokeDasharray: 1177,
                strokeDashoffset: 1177,
                stroke: "#FFFFFF",
              }}
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_2360_450">
              <rect width="420" height="1324" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="content-wrapper">
        {/* <ProcessSection /> */}
        <FaqSection />
      </div>
    </div>
  );
};

export default SvgScrollAnimation;
