import React from "react";
import CardsContainer from "../componenents/CardsContainer";
import "./Projects.scss";

const Projects = () => {
  return (
    <div className="projects-wrapper">
      <CardsContainer />
    </div>
  );
};

export default Projects;
