import { motion, useMotionValue, useTransform } from "framer-motion";
import { useEffect, useRef, useState, useCallback, memo } from "react";
import ImageContainer from "../componenents/ImageContainer";
import KnowMoreSection from "../componenents/KnowMoreSection";
import TextScrollSection from "../componenents/TextScrollSection";
import Forms from "../componenents/Forms";
import ctaPng from "../assets/ctaPng.png";
import "./Home.scss";
import projects from "../projects";
import Footer from "../componenents/Footer";
import ProcessSection from "../componenents/ProcessSection";
import GlitchImage from "../componenents/GlitchImage";
import Glitch1 from "../assets/Glitch1.jpeg";
import Glitch2 from "../assets/Glitch2.jpeg";
import Glitch3 from "../assets/Glitch3.jpeg";
import logo from "../assets/logo.png";
import ParallaxImages from "../componenents/ParallaxImages";

// Types
interface AnimatedTextProps {
  text: string[];
  className: string;
}

// Constants
const ANIMATION_DURATION = 0.5;
const ANIMATION_STAGGER = 0.1;
const SCROLL_BOUNDS = {
  MIN: 23.3,
  MAX: 80.6,
} as const;
const THE_TEXT = "THE ".split("");
const HEAVENLY_TEXT = "HEAVENLY".split("");
const CONSTRUCTIONS_TEXT = "GROUP".split("");
const IMAGES = Array(7).fill(
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/homeBg.jpg?alt=media&token=e31fb2a7-16b1-4fc2-9a20-91e13c30f5e0"
);
interface FormErrors {
  [key: string]: string;
}

// Animations
const textVariants = {
  initial: { y: 0, opacity: 0 },
  hovered: { y: "-110%", opacity: 0 },
};

const absoluteTextVariants = {
  initial: { y: "100%" },
  hovered: { y: 0 },
};

// Memoized Components
const AnimatedText = memo<AnimatedTextProps>(({ text, className }) => (
  <motion.div
    initial="initial"
    animate="hovered"
    className={className}
    style={{ lineHeight: 0.85 }}
  >
    <div>
      {text.map((letter: string, i: number) => (
        <motion.span
          key={i}
          variants={textVariants}
          transition={{
            duration: ANIMATION_DURATION,
            ease: "easeInOut",
            delay: ANIMATION_STAGGER * i,
          }}
          className="inline-block"
        >
          {letter}
        </motion.span>
      ))}
    </div>
    <div className="absolute">
      {text.map((letter: string, i: number) => (
        <motion.span
          key={i}
          variants={absoluteTextVariants}
          transition={{
            duration: ANIMATION_DURATION,
            ease: "easeInOut",
            delay: ANIMATION_STAGGER * i,
          }}
          className="inline-block"
        >
          {letter}
        </motion.span>
      ))}
    </div>
  </motion.div>
));

AnimatedText.displayName = "AnimatedText";

const MemoizedImageContainer = memo(ImageContainer);

const useImageLoader = (imageUrl: string) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setLoading(false);
    img.onerror = () => setLoading(false);
  }, [imageUrl]);

  return loading;
};

const Home: React.FC = () => {
  const loading = useImageLoader(IMAGES[0]);
  const sectionRef = useRef<HTMLDivElement>(null);
  const scrollPercent = useMotionValue(0);
  const xTransform = useTransform(scrollPercent, [0, 100], ["0%", "-100%"]);
  const [errors, setErrors] = useState<FormErrors>({});

  const footerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!sectionRef.current) return;

    const sectionTop = sectionRef.current.getBoundingClientRect().top;
    const sectionHeight = sectionRef.current.offsetHeight;
    const windowHeight = window.innerHeight;
    const scrollPosition = windowHeight - sectionTop;
    const adjustedHeight = sectionHeight + windowHeight;
    const percentageScrolled = (scrollPosition / adjustedHeight) * 100;

    if (percentageScrolled < SCROLL_BOUNDS.MIN) {
      scrollPercent.set(0);
    } else if (percentageScrolled > SCROLL_BOUNDS.MAX) {
      scrollPercent.set(100);
    } else {
      const normalizedScroll =
        ((percentageScrolled - SCROLL_BOUNDS.MIN) /
          (SCROLL_BOUNDS.MAX - SCROLL_BOUNDS.MIN)) *
        100;
      scrollPercent.set(normalizedScroll);
    }
  }, [scrollPercent]);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const getRandomPosition = () => ({
    x: Math.random() * (window.innerWidth - 100),
    y: Math.random() * (window.innerHeight - 100),
    rotate: Math.random() * 360,
    scale: Math.random() * (1.5 - 0.8) + 0.8,
  });

  const [animationProps, setAnimationProps] = useState(getRandomPosition());

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationProps(getRandomPosition());
    }, 3000);

    // Clean up on unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="main-container">
      <div
        className="red-form-style"
        style={{
          position: "fixed",
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
          display: "hidden",
          pointerEvents: "none",
        }}
      >
        <motion.div
          initial={{
            opacity: 0,
            position: "absolute",
            top: "0%",
            left: "-100%",
            width: "100%",
            height: "100%",
          }}
          animate={
            isExpanded
              ? { opacity: 1, position: "fixed", left: 0, pointerEvents: "all" }
              : {}
          }
          transition={{ duration: 0.5 }}
          style={{
            color: "white",
            zIndex: 10,
            display: isExpanded ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="close-button"
            onClick={() => {
              setIsExpanded(false);
              setErrors({});
            }}
          >
            <div className={` line1 ${isExpanded ? "" : "spin"}`}></div>
            <div className={` line2 ${isExpanded ? "" : "spin"}`}></div>
          </div>
          <Forms errors={errors} setErrors={setErrors} />
        </motion.div>

        <motion.div
          onClick={handleExpand}
          initial={{
            width: "0px",
            height: "0px",
            borderRadius: "50%",
            backgroundColor: "#E73C37",
          }}
          animate={
            isExpanded
              ? {
                  width: "3000px",
                  height: "3000px",
                  borderRadius: "0%",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  bottom: "0%",
                  pointerEvents: "all",
                }
              : {}
          }
          transition={{ duration: 1 }}
          style={{
            backgroundColor: "#E73C37",
            position: "absolute",
            bottom: "40%",
            right: 0,
            zIndex: 2,
            cursor: "pointer",
          }}
        >
          {!isExpanded && (
            <div className="main-cta-style" onClick={handleExpand}>
              <img src={ctaPng} alt="cta" />
            </div>
          )}
        </motion.div>
      </div>

      <nav className={`nav-bar`}>
        <a className="head" href="/">
          <img src={logo} alt="logo" />
        </a>
        <div className="menu-btn">
          <div className="menu">
            <div className="hamburger-menu">
              <div className="line-menu"></div>
              <div className="line-menu"></div>
              <div className="line-menu"></div>
            </div>
          </div>
        </div>
        <div className="fullscreen-menu" />
      </nav>

      <header className="homeBgStyle">
        <div className="header-wrapper">
          <p style={{ opacity: 0.5 }}>Est. 1996</p>
          <p>BUILDING DREAMS</p>
          <div className="typewriter">
            <p className="typing">WHERE HEAVEN MEETS EARTH</p>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            marginTop: "auto",
          }}
        >
          <motion.div className="homeSkylineBg" />
        </div>

        <div className="glitchWrapper">
          <GlitchImage src={Glitch3} />
          <GlitchImage src={Glitch2} />
          <GlitchImage src={Glitch1} />
        </div>

        <AnimatedText text={THE_TEXT} className="text-animation" />
        <AnimatedText text={HEAVENLY_TEXT} className="text-animation-2" />
        <AnimatedText text={CONSTRUCTIONS_TEXT} className="text-animation-2" />
      </header>

      <ParallaxImages />

      <section className="image-section" ref={sectionRef}>
        <div className="wrapper">
          <div className="edges" />
          <div className="header-wrapper">
            <h1 className="heading">STORIES</h1>
          </div>
          <motion.div className="image-array" style={{ x: xTransform }}>
            {projects
              .slice(0, 5)
              .map(({ srcImage, title, description }, index) => (
                <MemoizedImageContainer
                  key={index}
                  src={srcImage}
                  title={title}
                  description={description}
                />
              ))}
          </motion.div>
        </div>
      </section>

      <TextScrollSection
        content="We deliver superior construction services by adhering to the highest standards of quality and craftsmanship, ensuring durability and aesthetic appeal in every project. Our commitment to excellence sets us apart in the industry."
        heading="Quality Craftsmanship"
        img1="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/whyus2.png?alt=media&token=88229087-eac0-41e6-ba96-41adea30fa07"
        img2="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/quality2.png?alt=media&token=e192977b-584e-40a2-b2d2-281727e86b15"
      />
      <TextScrollSection
        content="Your needs and preferences are our priority. Through transparent communication and exceptional service, we build strong relationships, ensuring complete customer satisfaction and delivering personalized solutions that exceed expectations."
        heading="Customer Satisfaction"
        img1="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/6%20(3).jpg?alt=media&token=12aeb1a1-de9a-47f4-affd-ff33e572e84d"
        img2="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/PHOTO-2024-08-21-12-45-33.jpg?alt=media&token=b1359b71-29e0-4314-a679-e4c70de696cd"
        dark
      />
      <TextScrollSection
        content="We are dedicated to environmentally friendly construction. By integrating eco-friendly materials and energy-efficient designs, we promote sustainability across all projects, contributing to a healthier planet and fostering eco-friendly communities."
        heading="Sustainable Practices"
        img1="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/whyus.png?alt=media&token=d5a21378-6d90-42f9-81de-1eb39d6cfda3"
        img2="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/PHOTO-2024-08-21-12-45-37.jpg?alt=media&token=d77ba4d7-b8d5-4195-973f-b66bf5a10f36"
      />

      <ProcessSection />

      <KnowMoreSection />

      <TextScrollSection
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum."
        heading="SECOND WHY?"
        img1="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/whyus.png?alt=media&token=d5a21378-6d90-42f9-81de-1eb39d6cfda3"
        img2="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/whyus2.png?alt=media&token=88229087-eac0-41e6-ba96-41adea30fa07"
      />
      <Footer footerRef={footerRef} />
    </div>
  );
};

export default memo(Home);
