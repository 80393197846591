import React from "react";
import "./FaqSection.scss";

interface PlusIconProps {
  className?: string;
}

const PlusIcon: React.FC<PlusIconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#E73C37"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>
);

interface FAQItem {
  question: string;
  answer: string;
  category?: string;
}

const faqs: FAQItem[] = [
  {
    question: "What services do you offer?",
    answer:
      "We offer design, project planning, construction, and finishing services for residential and commercial projects.",
    category: "Services",
  },
  {
    question: "How do you calculate project costs?",
    answer:
      "Costs are based on design, materials, and labor. We provide a detailed estimate after an assessment.",
    category: "Pricing",
  },
  {
    question: "Do you offer customization for designs?",
    answer:
      "Yes, we provide tailored options to meet specific client preferences and requirements.",
    category: "Customization",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept major credit cards, bank transfers, and installment payments aligned with project milestones.",
    category: "Payments",
  },
  {
    question: "What is the typical project timeline?",
    answer:
      "Timelines vary by project size. We keep clients updated with regular progress reports.",
    category: "Timelines",
  },
  {
    question: "Do you provide warranties?",
    answer:
      "Yes, we offer warranties on construction quality and materials. Terms are detailed in the contract.",
    category: "Warranty",
  },
  {
    question: "What is the estimated time it will take to build a full house?",
    answer:
      "It takes 6 to 14 months to complete a house construction project. Generally, a 5-month period to build the first floor and 1.5 months for each one after that is the standard. However, this depends on the space, built-up area, locations, design, construction type (foundation or core), etc. You can track your project, including updates, inspection reports, payment schedules, etc., on the customer app.",
    category: "Timelines",
  },
];

const FAQAccordion = () => {
  const [expandedIndex, setExpandedIndex] = React.useState<number | null>(null);
  const [searchQuery, setSearchQuery] = React.useState("");

  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleAccordion = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-wrapper">
        <div className="header">
          <h2>Frequently Asked Questions</h2>
          <p>Everything you need to know</p>
        </div>

        <div className="search-bar">
          <input
            type="text"
            placeholder="Search questions..."
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="faq-list">
          {filteredFaqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <button
                className="question-button"
                onClick={() => toggleAccordion(index)}
              >
                <span className="question-text">{faq.question}</span>
                <div>
                  <PlusIcon
                    className={`icon ${
                      expandedIndex === index ? "active" : ""
                    }`}
                  />
                </div>
              </button>

              <div
                className={`answer ${expandedIndex === index ? "active" : ""}`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>

        {filteredFaqs.length === 0 && (
          <div className="no-results">No matching questions found</div>
        )}
      </div>
    </div>
  );
};

export default FAQAccordion;
