interface IProject {
  title: string;
  description: string;
  srcImage: string;
  images: string[];
}

const projects: Array<IProject> = [
  {
    title: "project1",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project1%2FPHOTO-2024-08-21-12-45-24.jpg?alt=media&token=032e173c-2c8c-421f-9eed-5324cf5ad148",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project1%2FPHOTO-2024-08-21-12-45-24.jpg?alt=media&token=032e173c-2c8c-421f-9eed-5324cf5ad148",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project1%2FPHOTO-2024-08-21-12-45-25.jpg?alt=media&token=e8f0731d-2185-4c9a-ae06-0207d405f609",
    ],
  },

  {
    title: "project3",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project3%2FPHOTO-2024-08-21-12-52-42.jpg?alt=media&token=0116246f-fc1c-45ed-b079-e63e100068f6",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project3%2FPHOTO-2024-08-21-12-52-42.jpg?alt=media&token=0116246f-fc1c-45ed-b079-e63e100068f6",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project3%2FPHOTO-2024-08-21-12-52-41.jpg?alt=media&token=cac24b89-0e87-4346-8a41-8fd647f829e4",
    ],
  },
  {
    title: "project4",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project4%2FPHOTO-2024-08-21-12-45-32%202.jpg?alt=media&token=4686450c-63b9-4b08-8e7e-c67537907a14",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project4%2FPHOTO-2024-08-21-12-45-32%202.jpg?alt=media&token=4686450c-63b9-4b08-8e7e-c67537907a14",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project4%2FPHOTO-2024-08-21-12-45-33%202.jpg?alt=media&token=a44ca965-ea5a-44e6-87a1-6ad873b561fe",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project4%2FPHOTO-2024-08-21-12-45-33%203.jpg?alt=media&token=f93e73fb-d421-48bf-9676-162f6dc6ecc4",
    ],
  },
  {
    title: "project5",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project5%2FPHOTO-2024-08-21-12-45-19.jpg?alt=media&token=36d6970b-ff8f-4ebf-a08d-84e6fe6ce1dd",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project5%2FPHOTO-2024-08-21-12-45-19.jpg?alt=media&token=36d6970b-ff8f-4ebf-a08d-84e6fe6ce1dd",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project5%2FPHOTO-2024-08-21-12-45-21.jpg?alt=media&token=481cb7cb-1f96-446e-b4cb-93de1f4a14d7",
    ],
  },
  {
    title: "project6",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project6%2FPHOTO-2024-08-21-12-45-35.jpg?alt=media&token=10a69774-2ebd-419f-95a8-c1be4d98dd04",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project6%2FPHOTO-2024-08-21-12-45-35.jpg?alt=media&token=10a69774-2ebd-419f-95a8-c1be4d98dd04",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project6%2FPHOTO-2024-08-21-12-45-36.jpg?alt=media&token=a2fac8ed-8302-4193-a8ae-4efc6c7d0b60",
    ],
  },
  {
    title: "project7",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project7%2FPHOTO-2024-08-21-12-45-34.jpg?alt=media&token=c0e86ff7-b8b6-43f0-8d70-b8c68257682a",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project7%2FPHOTO-2024-08-21-12-45-34.jpg?alt=media&token=c0e86ff7-b8b6-43f0-8d70-b8c68257682a",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project7%2FPHOTO-2024-08-21-12-45-33.jpg?alt=media&token=ca415fdf-1818-4826-9b73-345f4cc50310",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project7%2FPHOTO-2024-08-21-12-45-32.jpg?alt=media&token=16f5a7fe-9667-48eb-9322-6a30693f6941",
    ],
  },
  {
    title: "project8",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-42%202.jpg?alt=media&token=091528c3-e80e-4a0e-a9a8-5fa6a94231d9",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-42%202.jpg?alt=media&token=091528c3-e80e-4a0e-a9a8-5fa6a94231d9",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-41%203.jpg?alt=media&token=81401104-ca7e-42d5-9e22-06af0d79a711",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-41%202.jpg?alt=media&token=0603393d-e437-4fd1-bc6f-9a31b8940c58",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-40%203.jpg?alt=media&token=97e2da6f-375e-41c4-b058-a8a13cb73e66",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-40%202.jpg?alt=media&token=bb8dbb86-6e86-453e-b9c3-80ecc19dc16d",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-39.jpg?alt=media&token=d307c77c-0023-4517-8369-4575f09b11c4",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-39.jpg?alt=media&token=d307c77c-0023-4517-8369-4575f09b11c4",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-38.jpg?alt=media&token=62281386-8e08-4b35-84c1-a74c6c67527f",
    ],
  },
  {
    title: "project2",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project2%2FPHOTO-2024-08-21-12-45-27%203.jpg?alt=media&token=ab3c49c7-62f5-4c48-9e34-af1234e919c0",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project2%2FPHOTO-2024-08-21-12-45-27%203.jpg?alt=media&token=ab3c49c7-62f5-4c48-9e34-af1234e919c0",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project2%2FPHOTO-2024-08-21-12-45-27%202.jpg?alt=media&token=60bf92af-cae6-4ee6-8d84-f2d8339650d5",
    ],
  },
];

export default projects;
