"use client";
import { motion } from "framer-motion";
import "./AnimatedHeading.scss";

const letterVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
      duration: 1,
      ease: [0.215, 0.61, 0.355, 1],
    },
  }),
};

const wordVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.12,
      delayChildren: 0.3,
    },
  },
};

export default function AnimatedHeading() {
  const text = "HOW IT WORKS";
  const words = text.split(" ");

  return (
    <motion.h1
      className="animated-heading"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
    >
      {words.map((word, wordIndex) => (
        <motion.span
          key={wordIndex}
          className="animated-heading__word"
          variants={wordVariants}
        >
          {word.split("").map((char, charIndex) => (
            <motion.span
              key={charIndex}
              className="animated-heading__char"
              variants={letterVariants}
              custom={charIndex}
            >
              {char}
            </motion.span>
          ))}
        </motion.span>
      ))}
    </motion.h1>
  );
}
