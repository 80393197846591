import { motion, useScroll } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import projects from "../projects";

interface CardProps {
  bgColor?: string;
}

const Card = styled.div<CardProps>`
  width: 80%;
  height: 80%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 24px;
  img {
    max-width: 100%;
  }
`;

const OverlappingCards: React.FC = () => {
  const container = useRef(null);
  const [scrollValues, setScrollValues] = useState<
    { scale: number; rotate: number }[]
  >(Array(projects.length).fill({ scale: 1, rotate: 0 }));
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  useEffect(() => {
    const numCards = projects.length;
    const segment = 1 / numCards;

    return scrollYProgress.onChange((latest) => {
      const values = Array.from({ length: numCards }, (_, idx) => {
        const start = idx * segment;
        const end = (idx + 1) * segment;

        let scale = 1;
        let rotate = 0;

        if (latest >= end) {
          scale = 0.8;
          rotate = -5;
        } else if (latest >= start) {
          const progressWithinSegment = (latest - start) / segment;
          scale = 1 - 0.2 * progressWithinSegment;
          rotate = -5 * progressWithinSegment;
        }

        return { scale, rotate };
      });

      setScrollValues(values);
    });
  }, [scrollYProgress]);

  return (
    <div className="cards-container" ref={container}>
      {projects.map(({ title, description, srcImage }, idx) => (
        <motion.div
          className="cards-wrapper-style-projects"
          key={idx}
          style={{
            scale: scrollValues[idx]?.scale,
          }}
        >
          <Card>
            <img src={srcImage} alt="project-img" />
            <div className="card-title-style">{title}</div>
            <div className="card-description-style">{description}</div>
          </Card>
        </motion.div>
      ))}
    </div>
  );
};

export default OverlappingCards;
