import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import "./GlitchImage.scss";

interface GlitchImageProps {
  src: string;
}

const GlitchEffect = ({ src }: GlitchImageProps) => {
  // const containerRef = useRef<HTMLDivElement>(null);
  // const imageRef = useRef<HTMLImageElement>(null);

  // useEffect(() => {
  //   const container = containerRef.current;
  //   const imageElement = imageRef.current;

  //   if (!container || !imageElement) return;

  //   let scene: THREE.Scene;
  //   let camera: THREE.PerspectiveCamera;
  //   let renderer: THREE.WebGLRenderer;
  //   let planeMesh: THREE.Mesh;

  //   const ANIMATION_CONFIG = {
  //     updateFrequency: 0.1,
  //     glitchIntensityMod: 0.8,
  //   };

  //   const vertexShader = `
  //     precision highp float;
  //     varying vec2 vUv;
  //     void main() {
  //         vUv = uv;
  //         gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  //     }
  //   `;

  //   const fragmentShader = `
  //     precision highp float;
  //     uniform sampler2D tDiffuse;
  //     uniform float glitchIntensity;
  //     varying vec2 vUv;

  //     void main() {
  //         vec2 uv = vUv;
  //         vec4 baseState = texture2D(tDiffuse, uv);

  //         if (glitchIntensity > 0.0) {
  //             float segment = floor(uv.y * 12.0);
  //             float randomValue = fract(sin(segment * 12345.6789 + glitchIntensity) * 43758.5453);
  //             vec2 offset = vec2(randomValue * 0.03, 0.0) * glitchIntensity;

  //             vec4 redGlitch = texture2D(tDiffuse, uv + offset);
  //             vec4 greenGlitch = texture2D(tDiffuse, uv - offset);
  //             vec4 blueGlitch = texture2D(tDiffuse, uv);

  //             if (mod(segment, 3.0) == 0.0) {
  //                 gl_FragColor = vec4(redGlitch.r, greenGlitch.g, baseState.b, 1.0);
  //             } else if (mod(segment, 3.0) == 1.0) {
  //                 gl_FragColor = vec4(baseState.r, greenGlitch.g, blueGlitch.b, 1.0);
  //             } else {
  //                 gl_FragColor = vec4(redGlitch.r, baseState.g, blueGlitch.b, 1.0);
  //             }
  //         } else {
  //             gl_FragColor = baseState;
  //         }
  //     }
  //   `;

  //   const initializeScene = (texture: THREE.Texture) => {
  //     // Camera setup
  //     camera = new THREE.PerspectiveCamera(
  //       80,
  //       imageElement.offsetWidth / imageElement.offsetHeight,
  //       0.01,
  //       10
  //     );
  //     camera.position.z = 1;

  //     // Scene creation
  //     scene = new THREE.Scene();

  //     // Uniforms
  //     const shaderUniforms = {
  //       tDiffuse: { value: texture },
  //       glitchIntensity: { value: 0.0 },
  //     };

  //     // Adjust aspect ratio
  //     const aspectRatio = texture.image.width / texture.image.height;

  //     // Plane mesh with shader material
  //     planeMesh = new THREE.Mesh(
  //       new THREE.PlaneGeometry(2 * aspectRatio, 2),
  //       new THREE.ShaderMaterial({
  //         uniforms: shaderUniforms,
  //         vertexShader,
  //         fragmentShader,
  //       })
  //     );

  //     scene.add(planeMesh);

  //     // Renderer setup
  //     renderer = new THREE.WebGLRenderer();
  //     renderer.setSize(imageElement.offsetWidth, imageElement.offsetHeight);

  //     if (container) container.appendChild(renderer.domElement);
  //   };

  //   const animateScene = () => {
  //     requestAnimationFrame(animateScene);

  //     // Update glitch intensity randomly
  //     if (planeMesh) {
  //       (
  //         planeMesh.material as THREE.ShaderMaterial
  //       ).uniforms.glitchIntensity.value =
  //         Math.random() * ANIMATION_CONFIG.glitchIntensityMod;
  //     }

  //     renderer.render(scene, camera);
  //   };

  //   if (container) {
  //     const textureLoader = new THREE.TextureLoader();
  //     const imageUrl = imageElement.src;

  //     // Use texture loader with proper cross-origin handling
  //     textureLoader.load(
  //       imageUrl,
  //       (texture) => {
  //         initializeScene(texture);
  //         animateScene();
  //       },
  //       undefined, // onProgress callback
  //       (error) => {
  //         console.error("Error loading texture:", error);
  //       }
  //     );
  //   }

  //   return () => {
  //     // Cleanup on unmount
  //     if (container && container.firstChild) {
  //       container.removeChild(container.firstChild);
  //     }
  //   };
  // }, []);

  return (
    <div className="imageContainer">
      <img className="myImage" src={src} alt="Glitch Effect" />
    </div>
  );
};

export default GlitchEffect;
